import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "you-have-established-a-need-for-a-chatbot-based-on-extensive-market-validation-and-user-research-how-should-you-begin-to-think-about-designing-your-conversation"
    }}>{`You have established a need for a chatbot based on extensive market validation and user research. How should you begin to think about designing your conversation?`}</h3>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="IBM Design for AI: Conversation" href="http://ai-design.eu-de.mybluemix.net/design/ai/conversation/planning" type="resource" mdxType="ResourceCard">
          <img {...{
            "src": "/bee-673a4dd2872e72d77427915b63af68fc.svg",
            "alt": null
          }}></img>
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "conversational-writing"
    }}>{`Conversational writing`}</h2>
    <h3 {...{
      "id": "tailoring-the-conversation-to-your-users"
    }}>{`Tailoring the conversation to your users`}</h3>
    <p>{`Be sure to consider the tone of your bot. Tone should depend on the audience and the context in which the user will be engaging in a conversation. For example, a healthcare professional will require a different tone than the end user of a financial banking bot.`}</p>
    <p>{`Each bot requires a uniquely tailored experience. While in many cases the following principles will suffice, ensure that your bot’s tone is not distracting or at odds with users’ expectations.`}</p>
    <h4 {...{
      "id": "in-general-bots-should"
    }}>{`In general, bots should:`}</h4>
    <ul>
      <li parentName="ul">{`Use simple language that is easy to understand`}</li>
      <li parentName="ul">{`Be helpful and agreeable, but not forced or insincere`}</li>
      <li parentName="ul">{`Confirm its understanding of user inputs when appropriate (i.e., by paraphrasing).`}</li>
      <li parentName="ul">{`Provide help or a menu of options when a user is stuck`}</li>
    </ul>
    <h4 {...{
      "id": "typically-bots-should-not"
    }}>{`Typically, bots should not:`}</h4>
    <ul>
      <li parentName="ul">{`Attempt to seem like a real human`}</li>
      <li parentName="ul">{`Fail to provide an answer without recourse`}</li>
      <li parentName="ul">{`Be pushy or send unsolicited messages`}</li>
    </ul>
    <p>{`See the voice and tone guide from `}<a parentName="p" {...{
        "href": "http://www.carbondesignsystem.com/guidelines/content/general"
      }}>{`Carbon`}</a>{` for additional writing guidance.`}</p>
    <h3 {...{
      "id": "conversation-design-principles-for-bots"
    }}>{`Conversation design principles for bots`}</h3>
    <p>{`Ensure the success of your bot by following these principles:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Introduce your bot.`}</strong>{` Be crisp and clear about the identity of your bot, the value it can provide and why the user should trust it. Make it clear to your users that they are chatting with bot, not a real human. And, be sure to provide clear next steps.`}<ul parentName="li">
          <li parentName="ul">{`Is your bot setting the right expectations?`}</li>
          <li parentName="ul">{`Has your bot set the boundaries of what it can do?`}</li>
        </ul></li>
      <li parentName="ol"><strong parentName="li">{`Orient the user.`}</strong>{` Inform the user about what they can do, ask, or find. Don’t assume users will understand the scope of topics that can be discussed.`}</li>
      <li parentName="ol"><strong parentName="li">{`Be proactive.`}</strong>{` Suggest options (structured responses) as effective inputs that will help the user get what they are looking for or trying to do. Actively identify scenarios where guidance might be needed for users that don’t know what to ask or how to ask it.`}</li>
      <li parentName="ol"><strong parentName="li">{`Decrease ambiguity.`}</strong>{` Confirm user intents that have significant consequences by repeating what is about to happen (“To confirm, you want to move \\$300.00 to Checking?”). Summarize complex input from the user to confirm the bot’s understanding. Where possible, use structured responses to prevent dead-ends, unknowns, or chats that go off in tangents.`}</li>
      <li parentName="ol"><strong parentName="li">{`Provide clear actions.`}</strong>{` Provide a menu of structured responses narrow the scope of the input. Don’t overwhelm the user with too many choices.`}</li>
      <li parentName="ol"><strong parentName="li">{`Guide the user to their goal.`}</strong>{` Ensure successful interactions by providing guidance and feedback during complex processes. Notify the user how long a process might take, or if they are on the right track. Where appropriate allow users to cancel or restart longer processes.`}</li>
      <li parentName="ol"><strong parentName="li">{`Get feedback.`}</strong>{` Collect user feedback to improve your bot’s relevance and accuracy over time.`}</li>
    </ol>
    <h4 {...{
      "id": "references"
    }}>{`References: `}</h4>
    <ol>
      <li parentName="ol">{`Adam Cutler, Zach Causey and Lawrence Humphrey, IBM Design Conversational Experience Guide (2017)`}</li>
      <li parentName="ol">{`Amit Shevat, Designing Bots: Creating Conversational Experiences (2017)`}</li>
      <li parentName="ol">{`Cathy Pearl, Designing Voice User Interfaces: Principles of Conversational Experiences (2016)`}</li>
      <li parentName="ol">{`Erika Hall, Conversational Design, A Book Apart (2018)`}</li>
      <li parentName="ol">{`William R. Miller and Stephen Rollnick, Motivational Interviewing: Helping People Change (2012)`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      